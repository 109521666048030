export const data = [
  {
    id: `_0-Zdanowska`,
    header: `Hanna Zdanowska`,
    innerHtml: `Prezydent Miasta Łodzi do 13 grudnia 2010 roku.`
  },
  {
    id: `_1-Jonski`,
    header: `Dariusz Joński`,
    innerHtml: `były Wiceprezydent Łodzi, poseł na Sejm RP z ramienia KO, polityk Inicjatywy Polskiej, był członkiem zarządu Aquaparku FALA, zgodnie z oświadczeniem majątkowym za rok 2018 uzyskał dochód w łącznej wysokości ponad 86 tyś. złotych, <a href="http://orka.sejm.gov.pl/osw9.nsf/0/3E55C2D5A694C4E0C12584AA0059640C/%24File/OSW9P_146.pdf">źródło</a>`
  },
  {
    id: `_2-Rozpara`,
    header: `Tadeusz Rozpara`,
    innerHtml: `polityk SLD, członek rady nadzorczej EXPO-Łódź.`
  },
  {
    id: `_3-Pawlowski`,
    header: `Sylwester Pawłowski`,
    innerHtml: `polityk SLD, radny Rady Miejskiej w Łodzi, doradca zarządu w spółce EXPO-Łódź, zgodnie z oświadczeniem majątkowym za rok 2018 uzyskał dochód z tytułu zatrudnienia w wysokości ponad 110 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/pawlowski_sylwester_2019.pdf">źródło</a>
    `
  },
  {
    id: `_4-Berger`,
    header: `Jarosław Berger`,
    innerHtml: `były członek SLD, były radny Rady Miejskiej w Łodzi, pracował w Łódzkiej Spółce Infrastrukturalnej, obecne w BIONANOPARKu.`
  },
  {
    id: `_5-Bors`,
    header: `Piotr Bors`,
    innerHtml: `były radny sejmiku województwa łódzkiego, członek SLD, pracuje jako dyrektor Biura Promocji Zatrudnienia i Obsługi Działalności Gospodarczej Urzędu Miasta Łodzi.`
  },
  {
    id: `_6-Granatowski`,
    header: `Sławomir Granatowski`,
    innerHtml: `kandydował z SLD do Parlamentu Europejskiego i do Rady Miejskiej w Łodzi, pracuje jako dyrektor Departamentu Rewitalizacji i Sportu Urzędu Miasta w Łodzi, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_VG_20/granatowski_slawomir_200214.pdf">oświadczenie majątkowe</a>.`
  },
  {
    id: `_7-Klimczak`,
    header: `Marek Klimczak`,
    innerHtml: `były wiceprezydent Łodzi, związany z SLD, były członek rady nadzorczej spółki EXPO Łódź, obecnie: członek rady nadzorczej łódzkiego lotniska.`
  },
  {
    id: `_8-Bartosz`,
    header: `Katarzyna Bartosz`,
    innerHtml: `była radna Rady Miejskiej w Łodzi z SLD, został zatrudniona w 2016 roku w Grupowej Oczyszczalni Ścieków w Łodzi.`
  },
  {
    id: `_9-Walasek`,
    header: `Mateusz Walasek`,
    innerHtml: `radny Rady Miejskiej w Łodzi z PO, pracuje w Zakładzie Wodociągów i Kanalizacji w Łodzi, za rok 2018 osiągnął dochód ponad 127 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/walasek_mateusz_2019.pdf">źródło</a>`
  },
  {
    id: `_10-Reszpondek`,
    header: `Rafał Reszpondek`,
    innerHtml: `radny Rady Miejskiej w Łodzi z PO, został zatrudniony w Grupowej Oczyszczalni Ścieków w Łodzi.`
  },
  {
    id: `_11-Kepka`,
    header: `Karolina Kępka`,
    innerHtml: `radna Rady Miejskiej w Łodzi z PO, została zatrudniona w Wojewódzkim Funduszu Ochrony Środowiska i Gospodarki Wodnej w Łodzi, za rok 2018 osiągnęła dochód z umów o pracę w wysokości ponad 68 tyś. złotych,<a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/kepka_karolina_2019.pdf">źródło</a>`
  },
  {
    id: `_12-Budzinska`,
    header: `Joanna Budzińska`,
    innerHtml: `radna Rady Miejskiej w Łodzi z PO, została zatrudniona w Wojewódzkim Fundusz Ochrony Środowiska i Gospodarki Wodnej w Łodzi, za rok 2018 osiągnęła dochód z umów o pracę w wysokości ponad 75 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/budzinska_joanna_2019.pdf">źródło</a>`
  },
  {
    id: `_13-Parulska`,
    header: `Magdalena Parulska`,
    innerHtml: `była radna Rady Miejskiej w Łodzi z PO, została zatrudniona w Wojewódzkim Funduszu Ochrony Środowiska i Gospodarki Wodnej w Łodzi.`
  },
  {
    id: `_14-Domaszewicz`,
    header: `Bartosz Domaszewicz`,
    innerHtml: `radny Rady Miejskiej w Łodzi z PO, zatrudniony wcześniej w Atlas Arenie jako zastępca dyrektora oraz był kierownikiem w Wojewódzkim Funduszu Ochrony Środowiska i Gospodarki Wodnej w Łodzi, został zatrudniony w spółce Centrum Hotele, za rok 2018 osiągnął dochód z umowy o pracę w wysokości ponad 36 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/domaszewicz_bartosz_2019.pdf">źródło</a>`
  },
  {
    id: `_15-Skrzydlewska`,
    header: `Joanna Skrzydlewska`,
    innerHtml: `była europosłanka z PO, była radna sejmiku województwa łódzkiego, była dyrektor ds. rewitalizacji w Urzędzie Miasta Łodzi, obecnie: wiceprezydentka Miasta Łodzi, za rok 2018 osiągnęła dochód w wysokości ponad 100 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/skrzydlewska_joanna_191112.pdf">źródło</a>`
  },
  {
    id: `_16-Bohuszewicz`,
    header: `Sebastian Bohuszewicz`,
    innerHtml: `radny Rady Miejskiej w Łodzi z PO, wcześniej zatrudniony w Urzędzie Miasta Łodzi, były członek rady nadzorczej MPK Łódź, obecnie: Łódzka Spółka Infrastrukturalna, zasiadając w radzie nadzorczej MPK Łódź osiągnął dochód w wysokości ponad 47 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/bohuszewicz_sebastian_2019.pdf">źródło</a>`
  },
  {
    id: `_17-Blizniuk`,
    header: `Paweł Bliźniuk`,
    innerHtml: `były radny Rady Miejskiej w Łodzi z PO, radny sejmiku województwa Łódzkiego z PO, urlopowany w Łódzkiej Spółce Infrastrukturalnej, jest dyrektorem w Departamencie Obsługi i Administracji Urzędu Miasta Łodzi, zgodnie z oświadczeniem majątkowym za rok 2018 zarobił ponad 121 tyś. złotych, <a href="https://bip.lodzkie.pl/files/698/Oswiadczenia%20majatkowe/2019/Radni/Blizniuk_roczne.pdf">źródło</a>`
  },
  {
    id: `_18-Kacprzak`,
    header: `Tomasz Kacprzak`,
    innerHtml: `radny Rady Miejskiej w Łodzi z PO, jest dyrektor sortowni odpadów w Miejskim Przedsiębiorstwie Oczyszczania, za rok 2018 z tytułu umowy o pracę osiągnął dochód w wysokości ponad 123 tyś. złotych,<a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/kacprzak_tomasz_2019.pdf">źródło</a>`
  },
  {
    id: `_19-Wieteska`,
    header: `Agnieszka Wieteska`,
    innerHtml: `radna Rady Miejskiej w Łodzi z SLD, zatrudniona w Miejskim Przedsiębiorstwie Oczyszczania, za rok 2018 osiągnęła dochód w wysokości ponad 72 tyś. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/wieteska_agnieszka_2019.pdf">źródło</a>`
  },
  {
    id: `_20-Golaszewski`,
    header: `Marcin Gołaszewski`,
    innerHtml: `Przewodniczący Rady Miejskiej w Łodzi z ramienia KO, przewodniczący Miejskiej Komisji Rozwiązywania Problemów Alkoholowych.`
  },
  {
    id: `_21-Susnilo-Gruszka`,
    header: `Emilia Susniło-Gruszka`,
    innerHtml: `radna Rady Miejskiej w Łodzi z ramienia KO, pełni funkcję w Miejskiej Komisji Rozwiązywania Problemów Alkoholowych.`
  },
  {
    id: `_22-Moskwa`,
    header: `Małgorzata Moskwa-Wodnicka`,
    innerHtml: `była radna Rady Miejskiej w Łodzi z SLD, jako radna pracowała w MPO, obecnie: Wiceprezydent Łodzi, z tytułu umowy o pracę przed zostaniem wiceprezydentem Łodzi osiągnęła dochód w wysokości ponad 111 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/moskwa-wodnicka_malgorzata_191112.pdf">źródło</a>`
  },
  {
    id: `_23-Bartczak`,
    header: `Elżbieta Bartczak`,
    innerHtml: `radna Rady Miejskiej w Łodzi z KO, pełni funkcję w Miejskiej Komisji Rozwiązywania Problemów Alkoholowych.`
  },
  {
    id: `_24-Wieczorek`,
    header: `Adam Wieczorek`,
    innerHtml: `były radny Rady Miejskiej w Łodzi z PO, obecnie: Wiceprezydent Łodzi, pełni funkcję w Miejskiej Komisji Rozwiązywania Problemów Alkoholowych.`
  },
  {
    id: `_25-Riemer`,
    header: `Maciej Riemer`,
    innerHtml: `członek PO,  były kierownik projektu Expo International, potem Expo Horticultural, były pracownik wydziału ds. zarządzania projektami UMŁ, obecnie dyrektor Departamentu i Klimatu UMŁ, członek rady nadzorczej Miejskiego Przedsiębiorstwa Wodociągowego i Kanalizacji w Zduńskiej Woli.`
  },
  {
    id: `_26-Stępień`,
    header: `Witold Stępień`,
    innerHtml: `członek PO, były marszałek województwa Łódzkiego, pracował w UM w Konstantynowie Łódzkim, były przewodniczący rady nadzorczej Przedsiębiorstwa Gospodarki Komunalnej Gminy Konstantynów Łódzki, członek rady nadzorczej Zakładu Energetyki Cieplnej Pabianice. Obecnie radny sejmiku z PO, od 2019 - główny specjalista w BIONANOPARKu w Łodzi, <a href="https://dzienniklodzki.pl/a/ar/c1-14309965">źródło 1</a>, <a href="http://bip.zec.pabianice.pl/?cid=9">źródło 2</a>`
  },
  {
    id: `_27-Piotrowski`,
    header: `Tomasz Piotrowski`,
    innerHtml: `były radny sejmiku z PO, były specjalista ds. controllingu Centrum Hotele Sp. z o.o., były p. o. dyrektora biura prezydenta miasta oraz szef departamentu prezydent Hanny Zdanowskiej, obecnie prezes ŁSI; przed objęciem stanowiska prezesa osiągnął z tytułu umowy o pracę z Urzędu ponad 206 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/piotrowski_tomasz_u_190110.pdf">źródło</a>`
  },
  {
    id: `_28-Barszcz`,
    header: `Lech Barszcz`,
    innerHtml: `członek rady nadzorczej ŁSI, członek PO, <a href="https://dzienniklodzki.pl/lodzka-spolka-infrastrukturalna-oraz-zaklad-wodociagow-i-kanalizacji-maja-nowych-czlonkow-rad-nadzorczych-powolala-ich-hanna/ar/c1-14208465">źródło</a>`
  },
  {
    id: `_29-Kowalik`,
    header: `Robert Kowalik`,
    innerHtml: `były pracownik Urzędu Marszałkowskiego w Łodzi, był pełniącym obowiązki dyrektora Departamentu Polityki Społecznej i Zieleni, wcześniej dyrektor Wydziału Zdrowia, obecnie dyrektor Departamentu Polityki Społecznej UMŁ, członek rady nadzorczej ŁSI, <a href="https://dzienniklodzki.pl/lodzka-spolka-infrastrukturalna-oraz-zaklad-wodociagow-i-kanalizacji-maja-nowych-czlonkow-rad-nadzorczych-powolala-ich-hanna/ar/c1-14208465">źródło</a>`
  },
  {
    id: `_30-Bugajski`,
    header: `Marcin Bugajski`,
    innerHtml: `były dyr. departamentu w Urzędzie Marszałkowskim, były dyrektor zgierskiej ciepłowni, były dyr. w MPK Łódź, obecnie radny sejmiku województwa łódzkiego, członek PO, były wiceprezydent Łodzi i były wicemarszałek województwa łódzkiego, wcześniej członek zarządu i wiceprezes MPO Łódź, członek rady nadzorczej WTBS, za rok 2019 zarobił w MPO ponad 80 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_20/bugajski_marcin_200401_2.pdf">źródło 1</a>, <a href="https://dzienniklodzki.pl/standardy-chciwosc-pis-i-odlot-radnego-platformy/ar/13498895">źródło 2</a>`
  },
  {
    id: `_31-Stępień`,
    header: `Radosław Stępień`,
    innerHtml: `prezes TBS, były wiceminister w rządzie PO, były szef gabinetu politycznego min. Grabarczyka, , były wiceprezydent Łodzi, następnie wiceprezes zarządu Banku Gospodarstwa Krajowego. Doradca prezydentki Hanny Zdanowskiej. Obecnie prezes WTBS., za rok 2019 uzyskał ponad 277 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_20/stepien_radoslaw_200505.pdf">źródło</a>`
  },
  {
    id: `_32-Adamska-Makowska`,
    header: `Anna Adamska-Makowska`,
    innerHtml: `prezes Expo, kiedyś SLD teraz PO, żona radnego Krzysztofa Makowskiego z KO, za rok 2018 osiągnęła ponad 235 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/adamska-makowska_anna_190429.pdf">źródło</a>`
  },
  {
    id: `_33-Jaksa`,
    header: `Arkadiusz Jaksa`,
    innerHtml: `były kandydat na prezydenta Pabianic z ramienia PO, prezes ZOO w Łodzi, za rok 2019 uzyskał ponad 254 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_20/jaksa_arkadiusz_200520.pdf">źródło</a>`
  },
  {
    id: `_34-Głuszkowski`,
    header: `Adam Głuszkowski`,
    innerHtml: `dyrektor ds. handlowych  w ZWiK, był w Kole Aktywności, zaplecze polityczne PO i prezydent Hanny Zdanowskiej.`
  },
  {
    id: `_35-Papierski`,
    header: `Zbigniew Papierski`,
    innerHtml: `prezes MPK - Łódź, za rok 2018 z tytułu zatrudnienia uzyskał ponad 330 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/papierski_zbigniew_190426.pdf">źródło</a>, członek PO, zajął stanowisko prezesa w MPK w wyniku konkursu.`
  },
  {
    id: `_36-Rosicki`,
    header: `Wojciech Rosicki`,
    innerHtml: `członek rady nadzorczej MPK - Łódź, wiceprezydent Łodzi, z umowy o pracę w UMŁ za rok 2018 uzyskał ponad 196 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/rosicki_wojciech_190418.pdf">źródło</a>`
  },
  {
    id: `_37-Mędrzak`,
    header: `Jan Mędrzak`,
    innerHtml: `członek zarządu PO w Łodzi, były radny miejski, od 2001 pracuje w MPK Łódź, w 2011 przewodniczący Rady Nadzorczej Pocztowej Agencji Usług Finansowych S.A (zał. w 2000 r. jako inicjatywa m.in. Poczty Polskiej), w 2014 awansował w MPK na dyrektora ds. eksploatacji, jednocześnie jako radny był szefem komisji nadzorującej gospodarkę komunalną, w tym transport publiczny, <a href="https://dzienniklodzki.pl/lukasz-magin-lodzki-radny-pis-awansowal-w-belchatowie-zostal-prezesem-przedsiebiorstwa-gospodarki-mieszkanowej/ar/13289734">źródło 1</a>, <a href="https://www.se.pl/wiadomosci/polska/35-kumpli-ministra-cezarego-grabarczyka-lista-aa-hj5T-HZZr-DKXF.html">źródło 2</a>`
  },
  {
    id: `_38-Kaczorowski`,
    header: `Andrzej Kaczorowski`,
    innerHtml: `były radny miejski z PO, dyrektor MOPS, przez 8 lat na urlopie bezpłatnym, w czasie pełnienia funkcji radnego RM dyrektor Wojewódzkiego Urzędu Pracy w Łodzi, obecnie wrócił na stanowisko w MOPS.`
  },
  {
    id: `_39-Trela`,
    header: `Tomasz Trela`,
    innerHtml: `członek SLD, były pracownik UMŁ, potem: WFOŚ, ZWiK, były wiceprezydent Łodzi, obecnie poseł Lewicy, <a href="https://plus.dzienniklodzki.pl/garnkowi-z-pis-przyganial-kociol-z-sld/ar/12029772">źródło</a>`
  },
  {
    id: `_40-Tumiłowicz`,
    header: `Jarosław Tumiłowicz`,
    innerHtml: `były radny miejski z klub PO, zatrudniony w Międzynarodowych Targach Łódzkich, potem w Miejskiej Arenie Kultury i Sportu.`
  },
  {
    id: `_41-Obijalski`,
    header: `Marcin Obijalski`,
    innerHtml: `radny osiedlowy z list PO, były dyrektor Biura ds. Rewitalizacji, obecnie dyrektor ds. Inwestycji kubaturowych w ŁSI.`
  },
  {
    id: `_42-Arabski`,
    header: `Paweł Arabski`,
    innerHtml: `członek PO, ekspert Łódzkiej Spółki Infrastrukturalnej.`
  },
  {
    id: `_43-Bosakowski`,
    header: `Romuald Bosakowski`,
    innerHtml: `prezes TBS Zduńska Wola sp. z o.o., członek zarządu Łódzkiej Spółki Infrastrukturalnej i były członek zarządu w PKP, od 2012 prezes ŁSI, od 2013 prezes ZWiK, członek PO,<a href="https://lodz.naszemiasto.pl/romuald-bosakowski-dzialacz-platformy-obywatelskiej-w/ar/c3-1440057">źródło</a>`
  },
  {
    id: `_44-Cieślak`,
    header: `Marek Cieślak`,
    innerHtml: `były wiceprezydent  Łodzi u Hanny Zdanowskiej, były prezes ŁSSE, obecnie prezes BIONANOPARKu.`
  },
  {
    id: `_45-Kolczyński`,
    header: `Robert Kolczyński`,
    innerHtml: `p.o. Dyrektora Departamentu Architektury i Rozwoju UMŁ, wcześniej dyrektor Biura ds. Zarządzania Projektami w UMŁ, członek PO, za rok 2018 z umowy o pracę otrzymał ponad 134 tys. złotych, <a href="https://bip.uml.lodz.pl/files/bip/public/BIP_SS_19/kolczynski_robert_190426.pdf">źródło</a>`
  },
];
