import './style.css';
import { data } from './faceData.js'
const faces = document.querySelectorAll('ellipse');

faces.forEach(faceEllipse => {
  // console.log(faceEllipse)
  faceEllipse.classList.add('pop');
  const faceData = data.find(face => face.id === faceEllipse.id);
  faceEllipse.setAttribute('title', faceData.header);
  faceEllipse.setAttribute('data-content', faceData.innerHtml);
  faceEllipse.setAttribute('data-toggle', "popover");
  faceEllipse.setAttribute('data-placement', "top");

  const faceImageElement = document.getElementById(faceEllipse.id + "-img");
  // console.log(faceImageElement);
  faceEllipse.onmouseover = () => faceImageElement.style = `filter: none`;
  faceEllipse.onmouseout = () => faceImageElement.style = ``;
})

$(".pop").popover({ trigger: "manual", html: true, animation: false })
  .on("mouseenter", function () {
    var _this = this;
    $(this).popover("show");
    $(".popover").on("mouseleave", function () {
      $(_this).popover('hide');
    });
  }).on("mouseleave", function () {
    var _this = this;
    setTimeout(function () {
      if (!$(".popover:hover").length) {
        $(_this).popover("hide");
      }
    }, 50);
  });